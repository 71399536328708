import React, { useEffect } from 'react';
import './contact.css'; // Import the CSS file
import { Helmet } from 'react-helmet';

function Contact() {
  useEffect(() => {
    document.title = "Delhi chapter of ISHRAE - Contact Us";
  }, []);
  return (

    <>
    <div className='contactContainer'>
      
      <Helmet>

<title>Contact- DC ISHRAE </title>
<meta name="description" content="Contact- DC ISHRAE"/>
<meta name="keywords" content="" />
<link rel="canonical" href="https://dcishrae.in/contact" />
<meta property="og:type" content="website" />
<meta property="og:title" content="Contact- DC ISHRAE" />
<meta property="og:description" content="" />
<meta property="og:url" content="https://dcishrae.in/contact" />
<meta property="og:site_name" content="DC ISHRAE " />
<meta property="og:image" content=""/>
<meta name="twitter:card" content="summary" />
<meta name="twitter:title" content="Contact- DC ISHRAE" />
<meta name="twitter:description" content="" />
<meta name="twitter:image" content="" />


      </Helmet>

      <div className='contacthead'>
        <h1>CONTACT US</h1>
      </div>
      <div className="container" id='contact'>
        <div className="content">
          <div className="left-side">
            <div className="address details">
              <i className="fas fa-map-marker-alt"></i>
              <div className="topic">Address</div>
              <div className="text-one">1 Kehar Singh Estate, Basement Lane No. 2, Saidullajab
              Near Saket Metro Station, New Delhi-110030</div>
            </div>
            <div className="phone details">
              <i className="fas fa-phone-alt"></i>
              <div className="topic">Phone</div>
              <div className="text-one">+91-11-40214985</div>
              <div className="text-two"></div>
            </div>
            <div className="endpoint details">
              <i className="fas fa-network-wired"></i> 
              <div className="topic">Email and Website</div>
              <div className="text-one"><a href='info@dcishrae.org'>info@dcishrae.org</a></div>
              <div className="text-two"><a href='https://www.dcishrae.org'>www.dcishrae.org</a></div>
            </div>
          </div>
          <div className="right-side">
            <div className="topic-text">Send us a message</div>
            <p>If you have any work for me or any queries related to my tutorial, you can send me a message from here. It's my pleasure to help you.</p>


            <form   id="contact_form"
              action="https://api.web3forms.com/submit"
              method="POST">
   <input
                  type="hidden"
                  name="access_key"
                  value="24e5846d-bd9e-4ae1-bc11-905deb2bbcbe"
                  />
              <div className="input-box">
                <input type="text" name='name' placeholder="Enter Your Name" required />
              </div>
              <div className="input-box">
                <input type="text" name='phone no.' placeholder="Enter Your Number" required />
              </div>
              <div className="input-box">
                <input type="email" name='email' placeholder="Enter Your Email" required />
              </div>
              <div className="input-box message-box">
                <textarea name='message' placeholder="Enter Your Message" required></textarea>
              </div>
              <div className="button">
                <button type="submit" >Submit Now</button>

              </div>

            </form>
          </div>
        </div>
      </div>
                  </div>
    </>
  );
}

export default Contact;
