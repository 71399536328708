import React from "react";
import { Link } from "react-router-dom";
import logo from "./logo.png";
import "./navbar.css";
import pdf from '../navbar/ishrae.pdf'
const Navbar = () => {
  return (
    <nav style={{ zIndex: '999', width: '100%', marginBottom: '2rem'}} id="stickynav" className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img id="nav-logo" src={logo} alt="Logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/">
                Home
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link to="/about" className="nav-link">
                About DCISHRAE
              </Link>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <Link className="dropdown-item" to="/about-presidents">
                    Delhi Chapter Presidents
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <Link className="dropdown-item" to="/about-cwc">
                    CWC Members
                  </Link>
                </li>
              </ul>
            </li>

            
            <li className="nav-item dropdown">
  <Link
    className="nav-link dropdown-toggle"
    to="#"
    role="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    I-SHARE
  </Link>
  <ul className="dropdown-menu">
    <li>
      <a
        className="dropdown-item"
        href={pdf}
        target="_blank"
      >
        I-SHARE Vol. XV Issue 1 2024-25
      </a>
    </li>
    <li>
      <Link className="dropdown-item" to="/">
        I-SHARE 1st Issue SY 2021-22
      </Link>
    </li>
    <li>
      <Link className="dropdown-item" to="/">
        I-SHARE (Vol XI, Issue 2) 2020-21
      </Link>
    </li>
    <li>
      <hr className="dropdown-divider" />
    </li>
    <li>
      <Link className="dropdown-item" to="/">
        I-SHARE (Vol XI, Issue 1) 2020-2021
      </Link>
    </li>
    <li>
      <Link className="dropdown-item" to="/">
        I-SHARE (Vol X, Issue 4) 2020
      </Link>
    </li>
  </ul>
</li>

            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                News and Events
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/">
                    Previous Events
                  </Link>
                </li>
              </ul>
            </li>
            
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/blog">
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/contact">
                Contact Us
              </Link>
            </li>
          </ul>
          
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
