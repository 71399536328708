import React from "react";

const Terms = () => {
  return (
    <>
      <div className="container">
        <div className="privacyhead">
          <h1>Terms & Conditions</h1>
        </div>

        <div className="termfirst">
          <p>
            Welcome to DCDCISHRAE.IN and its affiliates provide their services
            to you subject to the terms and conditions given hereunder. If you
            visit us at DCISHRAE.IN, you accept these terms and conditions. In
            addition, when you use any current or future DCISHRAE.IN service
            (e.g. Your Account/Profile, etc.) or visit or purchase from any
            business affiliated with DCISHRAE.IN, whether or not included in the
            DCISHRAE.IN web site, you will be subject to these guidelines and
            conditions for such service or business. By using the shopping
            services of DCISHRAE.IN you agree to be bound by these terms and
            conditions. All products/services and information displayed on
            DCISHRAE.IN constitute an “invitation to offer”. Your order for
            purchase constitutes your “offer” which shall be subject to the
            terms and conditions as listed below. DCISHRAE.IN reserves the right
            to accept or reject your offer.
          </p>
          <p>
            <b>
              Please read these Terms & Conditions carefully before using this
              website. In these Terms & Conditions, DCISHRAE.IN is referred to
              as “ISHHRAE” or “Company” “we,” “us,”, “our” or Company and you
              the end user is referred to as “you” , “your” or “user”.
            </b>
          </p>
        </div>
        <hr />

        <div className="termsecond">
          <h3>DATA PROTECTION</h3>
          <p>
            DCISHRAE.IN may send information and offers of products and services
            to you from time to time. For further details relating to our policy
            relating to such offer please refer to our Privacy Policy.
          </p>
        </div>
        <hr />

        <div className="termthird">
          <h3>FORCE MAJEURE</h3>
          <p>
            DCISHRAE.IN shall have no liability to you for any interruption or
            delay in access to the Site irrespective of the cause.
          </p>
        </div>
        <hr />

        <div className="termforth">
          <h3>ELECTRONIC COMMUNICATION</h3>
          <p>
            When you visit DCISHRAE.IN or send e-mails to us or give us your
            feedback, you are communicating with us electronically. You consent
            to receive communications from us electronically. We will
            communicate with you by e-mail or by posting notices on this
            website. You agree that all agreements, notices, disclosures, and
            other communications that we provide to you electronically satisfy
            any legal requirement that such communications be in writing.
          </p>
        </div>
        <hr />

        <div className="termfifth">
          <h3>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h3>
          <p>
            DCISHRAE.IN has made this service available to use as a matter of
            convenience. We expressly disclaim any claim or liability arising
            out of uploading of any obscene, vulgar or pornographic images,
            photograph or a picture or altering or distorting the images
            available under this service in an obscene, vulgar or pornographic
            manner. We also disclaim all warranties, express or implied,
            including, but not limited to, implied warranties of merchantability
            and fitness for a particular purpose. We do not warrant that this
            site, its servers, or e-mail sent from the website are free of
            viruses or other harmful components. We will not be liable for any
            damages of any kind arising from the use of this site, including,
            but not limited to direct, indirect, incidental, punitive, and
            consequential damages.
          </p>
        </div>
        <hr />

        <div className="termsixth">
          <h3>DISCLAIMER AS TO THIRD PARTY WEB SITES (ADVERTISERS)</h3>
          <p>
            DCISHRAE.IN also disclaims any responsibility for the accuracy,
            content, or availability of information found on websites that link
            to or from DCISHRAE.IN website. DCISHRAE.IN is not liable for the
            accuracy, copyright compliance, legality, or decency of material or
            content contained in third-party sites and/or advertisements
            (including pop-up ads) and you hereby irrevocably waive any claim
            against us with respect to such sites or advertisements. DCISHRAE.IN
            does not ensure that you will be satisfied with any products or
            services that you purchase from any third-party site that links to
            or from DCISHRAE.IN websites.
          </p>
        </div>
        <hr />

        <div className="termseventh">
          <h3>GOVERNING LAW</h3>
          <p>
            This site is created and controlled by DCISHRAE.IN which is a Delhi
            Society. The laws of Delhi shall apply and courts in Delhi/New Delhi
            shall have jurisdiction in respect of all the terms, conditions and
            disclaimers. DCISHRAE.IN reserves the right to make changes to the
            website and the terms, conditions and disclaimers at any time and
            without information to the customers/users of the services/website
            of DCISHRAE.IN.
          </p>
        </div>
        <hr />

        <div className="termeighth">
          <h3>DISPUTE RESOLUTION</h3>
          <p>
            If there is any dispute about or involving the DCISHRAE.IN Website
            and/or the Service, by using the DCISHRAE.IN website, you agree that
            the dispute will be governed by the laws of India. You agree to the
            exclusive jurisdiction to the courts of Delhi, India.
          </p>
        </div>
        <hr />

        <div className="termeighth">
          <h3>CONTACT INFORMATION</h3>
          <p>
            If you have questions or concerns regarding these Terms &
            Conditions, please contact our support
          </p>
        </div>
        <hr />

        <div className="termtenth">
          <h3>Delhi Chapter of ISHRAE</h3>
          <p>
            1 Kehar Singh Estate, Basement
            <br />
            Lane No. 2, Saidullajab
            <br />
            Near Saket Metro Station
            <br />
            New Delhi – 110030E-mail:{" "}
            <a href="info@dcdcishrae.in">info@dcdcishrae.in</a>
          </p>
          <span>Tel : +91-11 40214985<br/>

E-mail : info@dcishrae.org</span>
        </div>
      </div>
    </>
  );
};

export default Terms;
