import React, { useEffect } from "react";
import delhi from "./delhi.jpeg";
import tree from "./tree.jpg";
import { Link } from "react-router-dom";
import "./home.css";
import aboutimg from "./aboutimg.webp";
import MyEventCalendar from "./EventCalander";
import CwcMembers from "./CwcMembers";
import adveng from "./adveng.webp";
import popprd from "./popprd.webp";
import plan from "./plan.webp";
import certificate from "./certificate.webp";
import target from "./target.png";
import activities from "./activities.png";
import president from "./Sandeep Goel New (1).png";
import academy from "./homeimg/academy.png";
import knowledge from "./homeimg/knowledge.png";
import newspaper from "./homeimg/newspaper.png";
import membership from "./homeimg/membership.png";
import Membership from "./Membership";
import Partners from "./partners/Partners";
import { Calendar } from "react-big-calendar";
import { Helmet } from "react-helmet";

const Home = () => {
  useEffect(() => {
    document.title = "Delhi chapter of ISHRAE - Home";
  }, []);

  return (

    <>
      <div id="carouselExampleCaptions" className="carousel slide">
            <Helmet>

            
<title>Delhi Chapter of Ishrae </title>
<meta name="description" content="DC ISHRAE, the Indian Society of Heating, Refrigerating & Air-conditioning Engineers was founded in 1981 in New Delhi by a group of ten eminent HVAC&R professionals."/>
<meta name="keywords" content="Delhi Chapter of ISHRAE, HVAC, HVAC R, ISHRAE, ASHRAE" />
<link rel="canonical" href="https://dcishrae.in/" />
<meta property="og:type" content="website" />
<meta property="og:title" content="Delhi Chapter of Ishrae" />
<meta property="og:description" content="DC ISHRAE, the Indian Society of Heating, Refrigerating & Air-conditioning Engineers was founded in 1981 in New Delhi by a group of ten eminent HVAC&R professionals." />
<meta property="og:url" content="https://dcishrae.in/" />
<meta property="og:site_name" content="Delhi Chapter of Ishrae" />
<meta property="og:image" content="../navbar/logo.png"/>
<meta name="twitter:card" content="summary" />
<meta name="twitter:title" content="Delhi Chapter of Ishrae" />
<meta name="twitter:description" content="DC ISHRAE, the Indian Society of Heating, Refrigerating & Air-conditioning Engineers was founded in 1981 in New Delhi by a group of ten eminent HVAC&R professionals." />
<meta name="twitter:image" content="../navbar/logo.png" />

                
            </Helmet>

        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={delhi} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-md-block">
              <h1>Carbon Neutral Green Warrior</h1>
              <p>Delhi Chapter of ISHRAE</p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={tree} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-md-block">
              <h5>Building a Carbon Neutral Indian</h5>
              <p>Delhi Chapter of ISHRAE</p>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div id="about" className="container">
        <div className="about">
          <div className="card">
            <img src={aboutimg} alt="ISHRAE" />
            <div className="cardcontent">
              <h2>About Delhi chapter of ISHRAE</h2>
              <p>
                The Delhi Chapter of the Indian Society of Heating,
                Refrigerating and Air Conditioning Engineers (ISHRAE) is a
                vibrant hub for professionals, researchers, and students in the
                HVAC&R and building services industry. Established as a
                cornerstone of ISHRAE’s nationwide network, the Delhi Chapter is
                committed to advancing knowledge, fostering innovation, and
                promoting sustainable practices in building systems and
                environmental control.
                <br />
              
              </p>
              <Link to="/about">Read More</Link>
            </div>
          </div>

          <div className="card2">
            <div className="card2head">
              <h2>ISHRAE Objectives</h2>
            </div>
            <div className="objectivecards">
              <div className="objectivecard">
                <img src={target} alt="ISHRAE" />
                <div className="objectivecardcontent">
                  <h4>ISHRAE MISSION</h4>
                  <p>
                    To promote the goals of the Society for the benefit of the
                    general public. Towards this objective, the Chapters of the
                    Society participate in, and organize, activities to protect
                    the Environment, improve Indoor Air Quality, help Energy
                    ....
                  </p>
                </div>
              </div>
              <div className="objectivecard">
                <img src={activities} alt="ISHRAE" />
                <div className="objectivecardcontent">
                  <h4>ACTIVITIES</h4>
                  <p>
                    As part of its objectives to promote the interests of the
                    HVAC&R Industry, ISHRAE is involved in various activities.
                    ISHRAE reaches out to all its members and seeks their active
                    participation & involvement in all the Events/Programs
                    organized by the society...
                  </p>
                </div>
              </div>
            </div>
            <button>
              <a href="/">View More</a>
            </button>
          </div>
        </div>

        <div className="courses">
          <div className="course">
            <img src={adveng} alt="DC ISHRAE" />
            <div className="coursecontent">
              <h5>DIPLOMA COURSE-IIE </h5>
              <p>Advanced Engineering Diploma Course</p>
              <button>
                <a href="https://iie.ishrae.in/" target="_blank">
                  Know More
                </a>
              </button>
            </div>
          </div>
          <div className="course">
            <img src={popprd} alt="DC ISHRAE" />
            <div className="coursecontent">
              <h5>SHOP NOW </h5>
              <p>Popular Products</p>
              <button>
                <a href="https://iie.ishrae.in/" target="_blank">
                  Know More
                </a>
              </button>
            </div>
          </div>
          <div className="course">
            <img src={plan} alt="DC ISHRAE" />
            <div className="coursecontent">
              <h5>MEMBERSHIP PLAN</h5>
              <p>Benefits of joining ISHRAE</p>
              <button>
                <a href="https://iie.ishrae.in/" target="_blank">
                  Know More
                </a>
              </button>
            </div>
          </div>
          <div className="course">
            <img src={certificate} alt="DC ISHRAE" />
            <div className="coursecontent">
              <h5>ICP COURSES </h5>
              <p>Certification program by ISHRAE</p>
              <button>
                <a href="https://iie.ishrae.in/" target="_blank">
                  Know More
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container" id="benefits">
        <div className="benefitshead">
          <h3>ISHRAE</h3>
          <span>BENEFITS OF JOINING DELHI CHAPTER OF ISHRAE</span>
          <p>A Premiere Technical Society Exclusively For Ac & R Community</p>
        </div>
        <div className="benefitscontent">
          <p>
            <i class="fa-solid fa-arrow-right"></i>Membership For Individual
            Only, No corporate Membership
          </p>
          <p>
            <i class="fa-solid fa-arrow-right"></i>A Premiere Technical Society
            Exclusively For AC & R Community
          </p>
          <p>
            <i class="fa-solid fa-arrow-right"></i>Provides An International
            Exposure through International Associates
          </p>
          <button>
            <a href="">Know More</a>
          </button>
        </div>
      </div>

      <div id="message" className="container">
        <div className="messageimg">
          <img src={president} alt=" DC ISHRAE" />
        </div>
        <div className="messagecontent">
          <h3>Mr. Sandeep Goel</h3>
          <h6>Society President's Message (SY: 2024-25)</h6>
          <p>
            Greetings!
            <br />I am honored to serve as President of the ISHRAE Delhi Chapter
            for 2024–25 and thank the past presidents and members for their
            invaluable support. This year, we aim to elevate the DCI banner with
            a clear vision for leadership, including fostering young talent and
            empowering women leaders. Key achievements include the India Cold
            Chain Conclave 2.0, impactful student programs, advocacy on
            decarbonization, and successful events like World Refrigeration Day.
            We look forward to an exciting year with initiatives like Shakti
            2024, the Youth Conclave, Acreconf, and the DCI Cup. Together, let’s
            achieve greater heights for DCI!
          </p>
        </div>
      </div>
      <div className="container" id="mission">
        <div className="missioncontent">
          <h2>DCI Cup</h2>
          <p>
            The lndian Society of Heating, Refrigerating and Air Conditioning
            Engineers (ISHRAE)
          </p>
          <div className="missioncards">
            <div className="missioncard">
              <img src={academy} alt="DC ISHRAE" />
              <h4>Education and Certification</h4>
            </div>
            <div className="missioncard">
              <img src={newspaper} alt="DC ISHRAE" />
              <h4>Publication & Resources</h4>
            </div>
            <div className="missioncard">
              <img src={knowledge} alt="DC ISHRAE" />
              <h4>Knowledge Bank</h4>
            </div>
            <div className="missioncard">
              <img src={membership} alt="DC ISHRAE" />
              <h4>Membership</h4>
            </div>
          </div>
        </div>
        <div className="missionyt">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/I86V2jMeiBQ?si=CgCno1EBQjF7haNF"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <br />
      <Membership />

      <div className="container" id="calander">
        <div className="calanderhead">
          <h2>UPCOMING EVENTS</h2>
        </div>
        <div className="calanderContainer">
          <MyEventCalendar />
        </div>
      </div>

      <div className="container">
        <CwcMembers />
      </div>
      <div className="container">
        <Partners />
      </div>
    </>
  );
};

export default Home;
