// Blog.js
import React, { useEffect } from "react";
import "./blog.css";
import { Link } from "react-router-dom";
import blog1img from  './blogimg/blog1img.jpg'
import blog2img from  './blogimg/blog2img.jpg'
import { Helmet } from "react-helmet";
const Blog = () => {
  useEffect(() => {
    document.title = "Delhi chapter of ISHRAE - Blogs";
  }, []);
  return (
    <>
      <div className="bloghead">
        
<Helmet> 
      <title>Blog- DC ISHRAE </title>
<meta name="description" content="Blog- DC ISHRAE"/>
<meta name="keywords" content=" DC ISHRAE Blog HVAC&R professionals, HVAC sciences, Delhi Chapter of ISHRAE, " />
<link rel="canonical" href="https://dcishrae.in/blog" />
<meta property="og:type" content="website" />
<meta property="og:title" content="Blog- DC ISHRAE" />
<meta property="og:description" content="" />
<meta property="og:url" content="https://dcishrae.in/blog" />
<meta property="og:site_name" content="DC ISHRAE " />
<meta property="og:image" content="https://dcishrae.in/static/media/blog1img.9bf7fd96c8a10449c082.jpg"/>
<meta name="twitter:card" content="summary" />
<meta name="twitter:title" content="Blog- DC ISHRAE" />
<meta name="twitter:description" content="" />
<meta name="twitter:image" content="https://dcishrae.in/static/media/blog1img.9bf7fd96c8a10449c082.jpg" />

</Helmet>

        <h1 className="text-center">BLOGS</h1>
      </div>
      <div className="blogcontainer container">
        <div className="blog-post">
          <div className="blog-post_img">
            <img
              src={blog1img}
              alt="Formal event"
            />
          </div>
          <div className="blog-post_info">
            <div className="blog-post_date">
              <span>DC Ishrae</span>
              <span>Dec 04 2024</span>
            </div>
            <h2 className="blog-post_title">
              <Link to="/blog/blog1">The Future of HVAC Industry in India
              </Link>
            </h2>
            <p className="blog-post_text">
            The heating, ventilation, and air conditioning (HVAC) industry in India is on the cusp of
significant transformation. As the Indian Society of Heating, Refrigerating and Air
Conditioning Engineers (ISHRAE) Delhi Chapter, we are uniquely positioned to observe and
influence these trends...
            </p>
          </div>
        </div>

        <div className="blog-post">
          <div className="blog-post_img">
            <img
              src={blog2img}
              alt="Conference room"
            />
          </div>
          <div className="blog-post_info">
            <div className="blog-post_date">
              <span>DC Ishrae</span>
              <span>Dec 04 2024</span>
            </div>
            <h2 className="blog-post_title">
              <Link to="/blog/blog2">Radiant Cooling : The Future of Energy-Efficient
              </Link>
            </h2>
            <p className="blog-post_text">
            In the evolving world of building systems, radiant cooling is emerging as a revolutionary
technology, offering a sustainable and energy-efficient alternative to conventional air
conditioning...
            </p>
          </div>
        </div>

        

        {/* Add more blog posts as needed */}
      </div>
    </>
  );
};

export default Blog;
