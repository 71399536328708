import React from 'react'
import event3 from './event3.jpeg'

const Event3 = () => {
  return (
    <>
    <div className='eventheading'>

      <h1  className='text-center'>23rd November 2024</h1>
      <h3 className='text-center'>DCI cup Final Match</h3>
</div>
    <div className='container' id='calanderevent'>
<img src={event3} alt=''/>
    </div>
    </>
  )
}

export default Event3