import React from 'react'
import event12 from './event1-2.jpeg'

const Event2 = () => {
  return (
    <>
    <div className='eventheading'>

<h1  className='text-center'>10th November 2024</h1>
<h3 className='text-center'>League Match</h3>
</div>
   <div className='container' id='calanderevent'>
<img src={event12} alt=''/>
    </div>
    </>
  )
}

export default Event2