import React, { useEffect } from "react";
import team from "./team.png";
import "./president.css";
import { Helmet } from "react-helmet";


const AboutPresidents = () => {
  useEffect(() => {
    document.title = "Delhi chapter of ISHRAE - Presidents";
  }, []);

  return (
    <>
      <div className="aboutpresident">

        <Helmet>

        <title>DC ISHRAE PRESIDETNS </title>
<meta name="description" content="Explore the journey of DC ISHRAE Presidents and their significant contributions to the Delhi Chapter of ISHRAE, a vibrant part of India's HVAC industry with over 1,700 members today."/>
<meta name="keywords" content="ISHRAE, Delhi Chapter of ISHRAE, Indian HVAC industry, Delhi Chapter, National organization, HVAC" />
<link rel="canonical" href="https://dcishrae.in/about-presidents" />
<meta property="og:type" content="website" />
<meta property="og:title" content="DC ISHRAE Presidents: Leaders Shaping the Delhi Chapter of ISHRAE" />
<meta property="og:description" content="Explore the journey of DC ISHRAE Presidents and their significant contributions to the Delhi Chapter of ISHRAE, a vibrant part of India's HVAC industry with over 1,700 members today." />
<meta property="og:url" content="https://dcishrae.in/about-presidents" />
<meta property="og:site_name" content="DC ISHRAE PRESIDETNS" />
<meta property="og:image" content="https://dcishrae.in/static/media/team.76bbaf35f56b9adbe3ce.png"/>
<meta name="twitter:card" content="summary" />
<meta name="twitter:title" content="DC ISHRAE Presidents: Leaders Shaping the Delhi Chapter of ISHRAE" />
<meta name="twitter:description" content="Explore the journey of DC ISHRAE Presidents and their significant contributions to the Delhi Chapter of ISHRAE, a vibrant part of India's HVAC industry with over 1,700 members today." />
<meta name="twitter:image" content="https://dcishrae.in/static/media/team.76bbaf35f56b9adbe3ce.png" />


        </Helmet>
        <div className="aboutpresidenthead"><h1>DC ISHRAE PRESIDETNS</h1></div>
        <div className="aboutpresidentcontent">
          <img src={team} alt="DC ISHRAE PRESIDETNS" />
          <p>
            Until 1990, <b> ISHRAE and Delhi Chapter of ISHRAE (DCI) </b> were
            synonymous. With the formation of the first chapter outside Delhi in
            Bangalore and subsequently in other Indian cities, ISHRAE became a
            National organization and Delhi Chapter became one of the
            constituent chapters of ISHRAE in 1990. Starting with a membership
            of about 250 in 1989, the Delhi Chapter today has more than 1700
            members. Delhi Chapter has had the honour of being led by some of
            the most prominent personalities of the Indian HVAC industry. Each
            one of them has made a significant contribution to make Delhi
            Chapter a vibrant organization today.
          </p>
        </div>

<div className="presidentlist">
    <h2>      THE FOLLOWING IS A CHRONOLOGICAL LIST OF DCI PRESIDETNS :</h2>
    <div className="presidents">

    
<ul>
    <li>1981-82 M.K. Jain</li>
    <li>1982-83 Dr. P.C. Jain</li>
    <li>1983-84 M.M. Pande</li>
    <li>1984-85 Rakesh Wadhwa</li>
    <li>1985-86 Prof. P.L. Ballaney</li>
    <li>1986-87 S.K. Sukhija</li>
    <li>1987-88 R.S. Mittal</li>
    <li>1988-89 T.K. Mukherjee</li>
    <li>1989-90 S. Suryanarayanan</li>
    <li>1990-91 V.K. Wadhwa</li>
    <li>1991-92 Rakesh Wadhwa</li>
    <li>1992-93 D.N. Sethi</li>
    <li>1993-94 Alok Bhardwaj</li>
    <li>1994-95 Uma Shanker</li>
    <li>1995-96 V.K. Seth</li>
</ul>
<ul>
    <li>1996-97 Amrish Chopra</li>
    <li>1997-98 G.C. Modgil</li>
    <li>1998-99 Dr. A.K. Saluja</li>
    <li>1999-00 Sanjeev Sachdeva</li>
    <li>2000-01 Sunil Gupta</li>
    <li>2001-02 Dushyant Nagpal</li>
    <li>2002-03 Richie Mittal</li>
    <li>2003-04 Anoop Ballaney</li>
    <li>2004-05 Ashish Rakheja</li>
    <li>2005-06 Sushil K. Choudhury</li>
    <li>2006-07 Amit Goel</li>
    <li>2007-08 B.P.S. Chadha</li>
    <li>2008-09 Rajnish Joshi</li>
    <li>2009-10 Sachin Maheshwari</li>
    <li>2010-11 Mukesh Puri</li>
</ul>
<ul>
    <li>2011-12 Ashwani Mehra</li>
    <li>2012-13 Sunil Kher</li>
    <li>2013-14 Sanjay Gupta</li>
    <li>2014-15 Abid Husain</li>
    <li>2015-16 Jagdeep Singh</li>
    <li>2016-17 Aneesh Kadyan</li>
    <li>2017-18 Gaurav Vasudev</li>
    <li>2018-19 Subir Das</li>
    <li>2019-20 Pradeep Kumar Dua </li>
    <li>2020-21 K.K. Mitra </li>
    <li>2021-22 K.D. Singh</li>
    <li>2022-23 Ashish Gupta</li>
    <li>2023-24 Priyank Garg</li>
    <li>2024-25 Sandeep Goel</li>
    
</ul>
</div>
<p>K-43 kailash colony used to be original ISHRAE DCI office up to 2008 later on it become ISHRAE HQ A major part of this acquisition was funded by donations from ISHRAE members and the industry. Before this i.e. between 1981 and 1987, all ISHRAE meetings used to be held at the residence of Dr. P.C. Jain, who was kind enough to host these meetings.

<hr/>
</p>
</div>
      </div>











    </>
  );
};

export default AboutPresidents;
