import React from "react";
import "./refundpolicy.css";

const RefundPolicy = () => {
  return (
    <>
      <div className="container">
        <div className="privacyhead">
          <h1>CANCELLATION AND REFUND POLICY</h1>
        </div>
        <div className="refundfirst">
          <h3>CANCELLATION POLICY</h3>
          <p>
            We make every effort to fulfill all the orders placed. However,
            please note that there may be certain orders that we are unable to
            process and must cancel. The reasons include limitations on
            quantities available for purchase, inaccuracies or errors in
            product, pricing and stock information, or problems identified by
            our credit and fraud avoidance department. Our Customer Service Team
            will communicate to you if all or any portion of your order is
            cancelled. If your order is cancelled by ISHRAE after your credit
            card has been charged, the said amount will be reversed back in your
            Card Account.
          </p>
        </div>
        <hr />

        <div className="refundsecond">
          <h3>CANCELLATION BY THE CUSTOMER</h3>
          <p>
            Please contact our Customer Service in case you wish to cancel the
            Order. On receipt of the cancellation notice, we shall cancel the
            order if the order has not been processed by us. We will not be able
            to cancel orders that have already been processed or shipped out by
            us. In some cases this can happen in an hour after you place the
            order.
          </p>
        </div>
        <hr />

        <div className="refundthird">
          <h3>REFUNDS POLICY</h3>
          <p>
            We will refund the money either by Net transfer/Cheque (Once
            approved by governing members). If the payment mode was Cheque/DD we
            will sent only to the billing address of the customer. We can refund
            via NEFT directly to your bank account also. Please note we will not
            be able to refund in cash. Typically, refunds are processed in
            minimum than 15 working days but in case of payments by Cheque or
            DD, it may take more time for the Cheque to be delivered to your
            billing address, and for the funds to be credited to your account,
            once you deposit the Cheque.
          </p>
        </div>
        <hr />

        <div className="refundforth">
          <h3>PAYMENT MODE</h3>
          <p>
            We accept only online payment methods. We don’t have facility for
            COD or Cash deposit.
          </p>
        </div>
        <hr />

        <div className="refundfifth">
          <h3>RETURNS</h3>
          <p>Product once sold will not be taken back</p>
        </div>
        <hr />
      </div>
    </>
  );
};

export default RefundPolicy;
