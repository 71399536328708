import React from "react";

const ShippingPolicy = () => {
  return (
    <>
      <div className="container">
        <div className="privacyhead">
          <h1>SHIPPING-POLICY</h1>
        </div>

        <div className="shippingfirst">
          <h3>SHIPPING AND DELIVERY POLICY</h3>
          <p>
            The Product/s will be shipped within 10-15 working days of the
            acceptance of the order.
          </p>

          <h3>SHIPPING CHARGES</h3>
          <p>
            0 to Rs. 500 —-Rs. 100
            <br />
            Rs. 501 to Rs. 1000—-Rs. 150
            <br />
            Above Rs. 1000 —-Rs. 200
          </p>
          <p>
            <b>
              If total item count in cart is 5 and more than 5 then shipping
              will be Free
            </b>
          </p>
        </div>
      </div>
    </>
  );
};

export default ShippingPolicy;
