import React from 'react'
import event12 from './event1-2.jpeg'
import './event1.css'


const Event1 = () => {
  return (
<>
<div className='eventheading'>

      <h1  className='text-center'>9th November 2024</h1>
      <h3 className='text-center'>League Match</h3>
</div>
    <div className='container' id='calanderevent'>
<img src={event12} alt=''/>
    </div>
</>
  )
}

export default Event1