import React, {useEffect} from "react";
import "./cwc.css";
import varunjain from './cwcimg/varun-jain.png'
import rajnish from './cwcimg/rajnish-agarwal.png'
import pradeep from './cwcimg/pradeep.png'
import gauravVasudev from './cwcimg/gaurav-vasudev.png'
import robin from './cwcimg/robin-chauhan.png'
import priyankaJain from './cwcimg/priyanka-jain.png'
import sheeba from './cwcimg/sheeba-siddiqui.png'
import amitKaushik from './cwcimg/amit-kaushilk.png'
import ruchiGrover from './cwcimg/ruchi-grover.png'
import sanjeevSeth from './cwcimg/sanjeev.png'
import sandeepKohli from './cwcimg/sandeep-kohli.png'
import pratimaSingh from './cwcimg/pratima-singh.png'
import dinesh from './cwcimg/dinesh.png'
import sushilKumar from './cwcimg/sushil-kumar.png'
import gauravShorey from './cwcimg/gourav-shoray.png'
import kartikSinghal from './cwcimg/kartik-singhal.png'
import praveenThakur from './cwcimg/praveen-thakur.png'
import vaibhavGupta from './cwcimg/vaibhav-gupta.png'
import shurtiGoel from './cwcimg/shruti-goel.png'
import pkGoel from './cwcimg/pk-goel.png'
import pareshMishra from './cwcimg/paresh-mishra.png'
import akshatbhtnagar from './cwcimg/akshat-bhtnagar.png'
import mdAdnan from './cwcimg/md-adnan.png'
import anubhaGoel from './cwcimg/anubha-goel.png'
import gauravMathur from './cwcimg/gaurav-mathur.png'
import ashishJain from './cwcimg/ashish-jain.png'
import abhishekjain from './cwcimg/abhishek-jain.png'
import priyankaKulshreshta from './cwcimg/priyanka.png'
import ashishGupta from './cwcimg/ashish-gupta.png'
import priyankGarg from './cwcimg/priyank-garg.png'
import sandeepGoel from './cwcimg/sandeep-goel.png'
import waliullahSiddiqui from './cwcimg/waliullah-siddiqui.png'
import samtaBjaj from './cwcimg/samta-bjaj.png'
import sukhdaTandon from './cwcimg/sukhda-tandon.png'
import { Helmet } from "react-helmet";




const CWC = () => {
  useEffect(() => {
    document.title = "Delhi chapter of ISHRAE - CWC Members";
  }, []);

  return (

    <>

<div class="cwccontainer" id="cwccontainer2" >


<Helmet>
<title>CWC Members - DC ISHRAE </title>
<meta name="description" content="CWC Members - DCI CHAPTER WORKING COMMITTEE 2024-25"/>
<meta name="keywords" content=" ISHRAE CWC members, HVAC&R professionals, ASHRAE, HVAC sciences, Delhi Chapter of ISHRAE, ISHRAE chapters" />
<link rel="canonical" href="https://dcishrae.in/about-cwc" />
<meta property="og:type" content="website" />
<meta property="og:title" content="CWC Members - DC ISHRAE" />
<meta property="og:description" content="CWC Members - DCI CHAPTER WORKING COMMITTEE 2024-25" />
<meta property="og:url" content="https://dcishrae.in/about-cwc" />
<meta property="og:site_name" content="DC ISHRAE PRESIDETNS" />
<meta property="og:image" content="https://dcishrae.in/static/media/sandeep-goel.70756061aa1d9395de3f.png"/>
<meta name="twitter:card" content="summary" />
<meta name="twitter:title" content="CWC Members - DC ISHRAE" />
<meta name="twitter:description" content="CWC Members - DCI CHAPTER WORKING COMMITTEE 2024-25" />
<meta name="twitter:image" content="https://dcishrae.in/static/media/sandeep-goel.70756061aa1d9395de3f.png" />

</Helmet>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={ashishGupta} alt="Ashish Gupta" />
            </div>
            <div class="contentBx">
              <h4>Ashish Gupta</h4>
              <h5>RD North 1</h5>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={priyankGarg} alt="Priyank Garg" />
            </div>
            <div class="contentBx">
              <h4>Priyank Garg</h4>
              <h6>Immediate</h6>
              <h5>Past President</h5>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={sandeepGoel} alt="Sandeep Goel" />
            </div>
            <div class="contentBx">
              <h4>Sandeep Goel</h4>
              <h5>President</h5>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={waliullahSiddiqui} alt="Waliullah Siddiqui" />
            </div>
            <div class="contentBx">
              <h4>Waliullah Siddiqui</h4>
              <h5>President - Elect</h5>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={samtaBjaj} alt=" Samta Bajaj" />
            </div>
            <div class="contentBx">
              <h4>Samta Bajaj</h4>
              <h5>Secretary</h5>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={sukhdaTandon} alt=" Sukhda Tandon " />
            </div>
            <div class="contentBx">
              <h4>Sukhda Tandon</h4>
              <h5>Treasurer</h5>
            </div>
          </div>
        </div>

        </div>

      <div className="cwccontainerhead">
        <h1>CWC MEMBERS</h1>
        <h3>DCI CHAPTER WORKING COMMITTEE 2024-25</h3>
      </div>
      <div class="cwccontainer ">
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={varunjain} alt=" Varun Jain" />
            </div>
            <div class="contentBx">
              <h4>Dr. Varun Jain</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={rajnish} alt=" Rajnish Aggarwal " />
            </div>
            <div class="contentBx">
              <h4>Mr. Rajnish Aggarwal</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={pradeep} alt=" Pradeep Kr. Dua " />
            </div>
            <div class="contentBx">
              <h4>Mr. Pradeep Kr. Dua</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={gauravVasudev} alt="Gaurav Vasudev" />
            </div>
            <div class="contentBx">
              <h4>Mr. Gaurav Vasudev</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={robin} alt="Robin Chauhan" />
            </div>
            <div class="contentBx">
              <h4>Mr. Robin Chauhan</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={priyankaJain} alt="Priyanka Jain" />
            </div>
            <div class="contentBx">
              <h4>Ms. Priyanka Jain</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={sheeba} alt="Sheeba Siddiqui" />
            </div>
            <div class="contentBx">
              <h4>Ms. Sheeba Siddiqui</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={amitKaushik} alt="Amit Kaushik" />
            </div>
            <div class="contentBx">
              <h4>Mr. Amit Kaushik</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={ruchiGrover} alt="Ruchi Grover" />
            </div>
            <div class="contentBx">
              <h4>Dr. Ruchi Grover</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={sanjeevSeth} alt="Sanjeev Seth" />
            </div>
            <div class="contentBx">
              <h4>Mr. Sanjeev Seth</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={sandeepKohli} alt="Sandeep Kohli" />
            </div>
            <div class="contentBx">
              <h4>Mr. Sandeep Kohli</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={pratimaSingh} alt="Pratima Singh" />
            </div>
            <div class="contentBx">
              <h4>Dr. Pratima Singh</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={dinesh} alt="Dinesh" />
            </div>
            <div class="contentBx">
              <h4>Mr. Dinesh</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={sushilKumar} alt="Sushil Kumar" />
            </div>
            <div class="contentBx">
              <h4>Mr. Sushil Kumar</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={gauravShorey} alt="Gaurav Shorey" />
            </div>
            <div class="contentBx">
              <h4>Mr. Gaurav Shorey</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={kartikSinghal} alt="Kartik Singhal" />
            </div>
            <div class="contentBx">
              <h4>Mr. Kartik Singhal </h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={praveenThakur} alt="Praveen Thakur" />
            </div>
            <div class="contentBx">
              <h4>Mr. Praveen Thakur</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={vaibhavGupta} alt="Vaibhav Gupta" />
            </div>
            <div class="contentBx">
              <h4>Mr. Vaibhav Gupta</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={shurtiGoel} alt="Shruti Goel" />
            </div>
            <div class="contentBx">
              <h4>Ms. Shruti Goel</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={pkGoel} alt="P.K. Goel" />
            </div>
            <div class="contentBx">
              <h4>Mr. P.K. Goel</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={pareshMishra} alt="Paresh Mishra" />
            </div>
            <div class="contentBx">
              <h4>Mr. Paresh Mishra</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={akshatbhtnagar} alt="Akshat Bhatnagar" />
            </div>
            <div class="contentBx">
              <h4>Mr. Akshat Bhatnagar</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={mdAdnan} alt="Md. Adnan" />
            </div>
            <div class="contentBx">
              <h4>Md. Adnan</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={anubhaGoel} alt="Anubha Goel" />
            </div>
            <div class="contentBx">
              <h4>Ms. Anubha Goel</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={gauravMathur} alt="Gaurav Mathur" />
            </div>
            <div class="contentBx">
              <h4>Mr. Gaurav Mathur</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={ashishJain} alt="Ashish Jain" />
            </div>
            <div class="contentBx">
              <h4>Mr. Ashish Jain</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={abhishekjain} alt="Abhishek Jain" />
            </div>
            <div class="contentBx">
              <h4>Mr. Abhishek Jain</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={priyankaKulshreshta} alt="Priyanka Kulshreshtha" />
            </div>
            <div class="contentBx">
              <h4>Ms. Priyanka Kulshreshtha</h4>
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default CWC;
