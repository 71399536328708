import React from "react";
import logo from "../navbar/logo.png";
import "./footer.css";

const Footer = () => {
  return (
    <footer
      style={{
        boxShadow: "10px 4px 6px rgba(0, 0, 0, 0.1)",
        padding: "2rem",
        paddingBottom: "1rem",
        background: "linear-gradient(225deg, hsla(134, 30%, 53%, 0.5) 0%, hsla(77, 59%, 55%, 0.4) 100%)",
      }}
    >
      <div className="footer-container">
        <div className="footer-section">
          <a href="/" className="footer-logo">
            <img alt="DCISHRAE" src={logo} width="200" />
          </a>
          <p className="footer-text">
            DC ISHRAE, the Indian Society of Heating, Refrigerating & Air-conditioning Engineers was founded in 1981
            in New Delhi by a group of ten eminent HVAC&R professionals.
          </p>
        </div>

        <div className="footer-links">
          <div className="footer-column">
            <h5>Home</h5>
            <a href="/">Home</a>
          </div>

          <div className="footer-column">
            <h5>About DCISHRAE</h5>
            <a href="/">Delhi Chapter Presidents</a>
            <a href="/">CWC Members</a>
          </div>

          <div className="footer-column">
            <h5>Blog</h5>
            <a href="/">Blog</a>
          </div>

          <div className="footer-column">
            <h5>Contact Us</h5>
            <a href="/">Contact Us</a>
          </div>

          <div className="footer-column">
            <h5>Information</h5>
            <a href="/privacy">Privacy Policy</a>
            <a href="/terms">Terms & Conditions</a>
            <a href="/shipping-policy">Shipping Policy</a>
            <a href="/refund-policy">Cancellation & Refund Policy</a>
          </div>
        </div>

        <div className="footer-social">
          <a href="https://www.facebook.com/DelhiChapterofISHRAE" target="_blank" rel="noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://www.youtube.com/@delhichapterofishrae2681/videos" target="_blank" rel="noreferrer">
            <i className="fab fa-youtube"></i>
          </a>
          <a href="https://www.instagram.com/dcishrae/" target="_blank" rel="noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/delhichapter/" target="_blank" rel="noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
        </div>

        <p className="footer-copyright">
          &copy; 2024 Delhi Chapter of ISHRAE. All rights reserved. No parts of this site may be copied without our
          written permission.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
