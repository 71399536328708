import React from "react";
import "./membership.css";
import { Link } from "react-router-dom";

const Membership = () => {
  return (
    <div id="membership" className="container">
      <div className="membershiphead mt-2">
        <h1 className="text-center">MEMBERSHIP PLANS</h1>
        <h4 className="text-center">Join Delhi Chapter of ISHRAE</h4>
      </div>

      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="true"
              aria-controls="flush-collapseOne"
            >
              New Membership For (3 Years)
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionPanelsStayOpenExample"
          >
            <div className="accordion-body">
              <ul>
                <li>Registration Fee : ₹ 2500.00/-</li>
                <li>Annual Fee : ₹ 5000.00/-</li>
                <li>GST @18 : 18.00%</li>
                <li>Total : ₹ 8850/-</li>
              </ul>
              <Link to="https://www.ishraehq.in/registration/MemberRegistration">Join Now</Link>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
             New Membership For (10 Years)

            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionPanelsStayOpenExample"
          >
            <div className="accordion-body">
              <ul>
                <li>Registration Fee : ₹ 2500.00/-</li>
                <li>Annual Fee : ₹ 12500.00/-</li>
                <li>GST @18 : 18.00%</li>
                <li>Total : ₹ 17700/-</li>
              </ul>
              <Link to="https://www.ishraehq.in/registration/MemberRegistration">Join Now</Link>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              Membership Renewal For (3 Years)
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionPanelsStayOpenExample"
          >
            <div className="accordion-body">
              <ul>
                <li>Renewal/Sustenance Fee : ₹ 5000.00/-</li>
                <li>GST @18 : 18.00%</li>
                <li>Total : ₹ 5900/-</li>
              </ul>
              <Link to="https://www.ishraehq.in/login?returnurl=/Member/Renewal">Join Now</Link>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour"
              aria-expanded="false"
              aria-controls="flush-collapseFour"
            >
              Membership Renewal For (10 Years)
            </button>
          </h2>
          <div
            id="flush-collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionPanelsStayOpenExample"
          >
            <div className="accordion-body">
              <ul>
                <li>Renewal/Sustenance Fee : ₹ 12500.00/-</li>
                <li>GST @18 : 18.00%</li>
                <li>Total : ₹ 14750/-</li>
              </ul>
              <Link to="https://www.ishraehq.in/login?returnurl=/Member/Renewal">Join Now</Link>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFive"
              aria-expanded="false"
              aria-controls="flush-collapseFive"
            >
             Student Membership Plan (1 year)

            </button>
          </h2>
          <div
            id="flush-collapseFive"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionPanelsStayOpenExample"
          >
            <div className="accordion-body">
              <ul>
                <li>Registration Fee : ₹ 720.00/-
                </li>
                <li>GST @18 : 18.00%
                </li>
                <li>Total : ₹ 850/-
                </li>
              </ul>
              <Link to="https://www.ishraehq.in/studentRegistration/JoinNow">Join Now</Link>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseSix"
              aria-expanded="false"
              aria-controls="flush-collapseSix"
            >
             Student Membership Plan (2 years)

            </button>
          </h2>
          <div
            id="flush-collapseSix"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionPanelsStayOpenExample"
          >
            <div className="accordion-body">
              <ul>
                <li>Registration Fee : ₹ 1271.00/-
                </li>
                <li>GST @18 : 18.00%
                </li>
                <li>Total : ₹ 1500/-
                </li>
              </ul>
              <Link to="https://www.ishraehq.in/studentRegistration/JoinNow">Join Now</Link>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseSeven"
              aria-expanded="false"
              aria-controls="flush-collapseSeven"
            >
             Student Membership Plan (3 years)

            </button>
          </h2>
          <div
            id="flush-collapseSeven"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionPanelsStayOpenExample"
          >
            <div className="accordion-body">
              <ul>
                <li>Registration Fee : ₹ 1653.00/-
                </li>
                <li>GST @18 : 18.00%
                </li>
                <li>Total : ₹ 1950/-
                </li>
              </ul>
              <Link to="https://www.ishraehq.in/studentRegistration/JoinNow">Join Now</Link>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Membership;
