import React, { useEffect } from "react";
import "./about.css";
import aboutimg from "./aboutimg.jpg";
import { Helmet } from "react-helmet";


const About = () => {
  useEffect(() => {
    document.title = "Delhi chapter of ISHRAE - About";
  }, []);
  return (
    <>
      <div className="about">
        
<Helmet>
      <title>About DC ISHRAE: Founding Members and Vision for India's HVAC&R Industry </title>
<meta name="description" content="DC ISHRAE, founded in 1981 by 10 visionary HVAC&R professionals in New Delhi. Learn about its mission to advance HVAC sciences and provide affordable membership for Indian professionals."/>
<meta name="keywords" content="Indian Society of Heating, Refrigerating & Air-conditioning Engineers, ISHRAE founding members, HVAC&R professionals, ASHRAE, HVAC sciences, Delhi Chapter of ISHRAE, ISHRAE chapters" />
<link rel="canonical" href="https://dcishrae.in/about" />
<meta property="og:type" content="website" />
<meta property="og:title" content="About DC ISHRAE: History, Founding Members, and Vision" />
<meta property="og:description" content="DC ISHRAE, founded in 1981 by 10 visionary HVAC&R professionals in New Delhi. Learn about its mission to advance HVAC sciences and provide affordable membership for Indian professionals." />
<meta property="og:url" content="https://dcishrae.in/about" />
<meta property="og:site_name" content="DC ISHRAE PRESIDETNS" />
<meta property="og:image" content="https://dcishrae.in/static/media/aboutimg.e4889d2617dd3bd50346.jpg"/>
<meta name="twitter:card" content="summary" />
<meta name="twitter:title" content="DC ISHRAE Presidents: Leaders Shaping the Delhi Chapter of ISHRAE" />
<meta name="twitter:description" content="DC ISHRAE, founded in 1981 by 10 visionary HVAC&R professionals in New Delhi. Learn about its mission to advance HVAC sciences and provide affordable membership for Indian professionals." />
<meta name="twitter:image" content="https://dcishrae.in/static/media/aboutimg.e4889d2617dd3bd50346.jpg" />

</Helmet>

        <div className="abouthead"><h1>ABOUT US</h1></div>

        <div className="aboutin">
          <img src={aboutimg} alt="About DC ISHRAE" />
          <div className="aboutincontent">
            <p>
              DC ISHRAE, the Indian Society of Heating, Refrigerating &
              Air-conditioning Engineers was founded in 1981 in New Delhi by a
              group of ten eminent HVAC&R professionals. These 10 founding
              members were:
            </p>
            <ul>
              <li>Dr. P.C. Jain</li>
              <li>Late Mr. M.M. Pande</li>
              <li>Mr. M.K. Jain</li>
              <li>Mr. D.N. Sethi</li>
              <li>Mr. V. K. Wadhwa</li>
            </ul>
            <ul>
              <li>Mr. N.C. Gupta</li>
              <li>Mr. N.S. Hukmani</li>
              <li>Mr. Anil Maheshwari</li>
              <li>Prof. P.L. Ballaney</li>
              <li>Mr. Rakesh Wadhwa</li>
            </ul>
            <p>
              Mr. M.K. Jain was the founding President of the Society. The
              founding members were inspired by ASHRAE, the American Society of
              Heating, Refrigerating & Air-conditioning Engineers but felt that
              ASHRAE membership could be beyond the reach of many Indian
              professionals due to its high membership fees (in Indian rupees).
              They therefore envisioned the need for a similar organization in
              India with an affordable fee structure for advancing the arts and
              sciences of HVAC&R
            </p>
          </div>
        </div>

        <div className="aboutaim ">
          <div className="aboutaimtop">
            <h2>THE AIMS AND OBJECTIVES OF ISHRAE</h2>
            <div className="aimobj">
              <div>
                Advancement of the arts and sciences of heating,
                air-conditioning and refrigeration engineering and related
                services.
              </div>
              <div>
                Continuing education of members and other interested persons in
                the said sciences through lectures, workshops, product
                presentations and publications.
              </div>
              <div>
                Rendition, career guidance and financial assistance to students
                of the said sciences
              </div>
              <div>Encouragement of scientific research.</div>
            </div>
          </div>

          <div className="aboutaimbottom">
            <p>
              Starting with 10 members in 1981 in New Delhi, ISHRAE grew rapidly
              and by 1989 there were about 250 members. In 1989, the first
              chapter outside Delhi was formed in Bangalore and subsequently
              chapters in other major Indian cities came up in the next 4 years
              as under:
            </p>

            <ul>
              <li>Bangalore (1989)</li>
              <li>Chennai (1989)</li>
              <li>Calcutta (1989)</li>
              <li>Hyderabad (1990)</li>
              <li>Ahmedabad (1990)</li>
              <li>Mumbai (1990)</li>
              <li>Pune (1993)</li>
            </ul>
          </div>
              <hr/>
        </div>

        <div className="chapters">
          <h2>TWO OVERSEAS CHAPTERS WERE ALSO ADDED</h2>
          <ul>
          <li>Muscat (1993)</li>
          <li>Kuwait (1994)</li>
          </ul>
          <p>
            The last chapter of ISHRAE was formed in Kochi in 2004. <br />
            {" "}
            <b>
              The total membership of ISHRAE today stands at more than 12000.
            </b>
          </p>
        </div>
      </div>
      <hr/>
    </>
  );
};

export default About;
